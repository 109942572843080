<template>
  <div class="wrapper">
    <parallax class="section page-header"
              parallax-active="true"
              :style="headerStyle">
      <div class="game-logo-wrap md-layout">
        <div class="game-head md-layout-item md-size-50 md-small-size-100">
          <img class="game-logo" :src="image_head_title" alt="blast">
        </div>
        <div class="game-head md-layout-item md-size-50 md-small-size-100">
          <img class="game-logo" :src="image_head_character" alt="blast">
        </div>
      </div>
      <div class="game-down-links">
        <a href="https://play.google.com/store/apps/details?id=com.wemadeplay.anipangblast">
          <img :src="pre_order_page_google_btn" alt="google_btn">
        </a>
        <a href="https://apps.apple.com/app/id1640186076">
          <img :src="pre_order_page_app_btn" alt="app_btn">
        </a>
      </div>
    </parallax>
    <div class="main">

      <div class="event section section-cards">
        <div class="container">
          <div class="section-description">
            <h1 class="game-title">
              <span>{{language.games_blast_event_title_1}}</span> {{language.games_blast_event_title_2}}
            </h1>
            <h3 class="game-description">
              {{language.games_coins_event_description}}
            </h3>
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-size-50 md-small-size-100 mx-auto">
              <img class="photo_image" :src="sections.event.rewards" alt="slot_machine">
            </div>
            <div class="md-layout-item md-size-50 md-small-size-100 mx-auto">
              <img class="photo_image" :src="sections.event.moca" alt="slot_machine">
            </div>
          </div>
        </div>
      </div>

      <div class="intro section section-cards" >
        <div class="container" >
          <div class="md-layout">
            <div class="md-layout-item md-size-50 md-small-size-100 text-center">
              <img :src="sections.intro.phone" alt="phone">
            </div>
            <div class="section-contents md-layout-item md-size-50 text-center md-small-size-100 mx-auto">
              <div class="section-description">
                <h1 class="game-title">
                  {{language.games_blast_intro_title}}
                </h1>
                <h3 class="game-description">
                  {{language.games_blast_intro_description}}
                </h3>
                <br><br>
                <md-button class="game-button" @click="goLink">
                  {{language.games_blast_intro_button}}
                </md-button>
                <br><br>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="moreTokens section section-cards">
        <div class="container">
          <div class="md-layout">
            <div class="section-contents md-layout-item md-size-50 md-small-size-100 mx-auto text-center">
              <div class="section-description">
                <h1 class="game-title">
                  {{language.games_blast_moreTokes_title}}
                </h1>
                <h3 class="game-description">
                  {{language.games_blast_moreTokes_description}}
                </h3>
              </div>
            </div>
            <div class="md-layout-item md-size-50 md-small-size-100">
              <img :src="sections.moreTokens.i" alt="i">
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Mixins from "@/plugins/basicMixins";

  export default {
    bodyClass: "ac-games-blast",
    mixins: [Mixins.HeaderImage],
    beforeMount() {
      if (!this.base.isDomestic) {
        this.$router.replace('/games/pre-register');
      }
    },
    components: {},
    computed: {
      ...mapGetters([
        'base',
        'language',
      ]),
    },
    methods: {
      goLink() {
        window.open('https://wemade-play.gitbook.io/anipang-club/', '_blank');
      }
    },
    data() {
      return {
        image_head_bg: require("@/assets/img/pages/games/blast/blast_main_bg.jpg"),
        image_head_title: require("@/assets/img/pages/games/blast/bi.png"),
        image_head_character: require("@/assets/img/pages/games/blast/character_main.png"),

        pre_order_page_app_btn: require("@/assets/img/pages/games/common/pre_order_page_app_btn.png"),
        pre_order_page_google_btn: require("@/assets/img/pages/games/common/pre_order_page_google_btn.png"),

        sections: {
          intro: {
            phone: require("@/assets/img/pages/games/blast/phone.png"),
          },
          moreTokens: {
            i: require("@/assets/img/pages/games/blast/character_2.png"),
          },
          event: {
            moca: require("@/assets/img/pages/games/blast/website_blast_EVENT_moca.png"),
            rewards: require("@/assets/img/pages/games/blast/website_blast_EVENT_rewards.png"),
          }
        }
      };
    }
  };
</script>

<style lang="scss" scoped>
  /*@font-face {*/
    /*font-family: 'yg-jalnan';*/
    /*src: url('../../assets/fonts/Jalnan.ttf') format('truetype'); !* chrome, firefox, opera, Safari, Android, iOS 4.2+*!*/
    /*font-weight: normal;*/
    /*font-display: 'auto';*/
    /*font-style: normal;*/
  /*}*/


  /*<img src="/static/frontend/images/info-card-1.jpg" alt="">*/

  $ac-section-bg: #ff6371 !default;


  .ac-games-blast {
    .page-header {
      height: auto;
      position: relative;

      .game-down-links {
        position: absolute;
        bottom: 10%;
        display: flex;
        justify-content: center;

        a {
          display: block;
          width: 50%;
          text-align: center;

          img {
            width: 90%;
          }
        }
      }
    }

    .game-logo-wrap {
      width: 100%;
      display: flex;
      align-items: center;

      .game-logo {
        width: 100%;
      }
    }

    .main {

      .event {
        background-image: url("../../assets/img/pages/games/blast/website_blast_EVENT_bg_1600x900.jpg");
        background-repeat: no-repeat;
        background-position: bottom center;
        background-size: cover;
        text-align: center;

        .game-title {
          span {
            color: #10673a;
          }
        }
        .game-description {
          font-family: 'yg-jalnan';
          color: #6ead3b !important;
        }
      }

      .intro {
        background-image: url("../../assets/img/pages/games/blast/forest.png");
        background-repeat: no-repeat;
        background-position: bottom right;
        background-size: auto;

        .container {
          .section-contents {}
          .section-description {
            max-width: 700px;
            margin: 0 auto;

            .game-title{
              color: #0F673A;

            }
            .game-description{
              color: #0F673A;
            }
          }
        }

      }

      .moreTokens {
        .container {
          .section-contents {
            display: flex;
            flex-wrap: wrap;
            align-content: center;
          }

          .section-description {
            text-align: left;
            margin: 0 auto;
            max-width: 700px;
          }
        }
      }


      .section:nth-child(2n) {
        background-color: $ac-section-bg;

        .section-description {
          text-shadow: 0 0 4px $ac-section-bg, 0 0 4px $ac-section-bg, 0 0 4px $ac-section-bg, 0 0 4px $ac-section-bg, 0 0 4px $ac-section-bg, 0 0 4px $ac-section-bg, 0 0 4px $ac-section-bg, 0 0 4px $ac-section-bg, 0 0 4px $ac-section-bg, 0 0 4px $ac-section-bg, 0 0 4px $ac-section-bg, 0 0 4px $ac-section-bg, 0 0 4px $ac-section-bg, 0 0 4px $ac-section-bg, 0 0 4px $ac-section-bg, 0 0 4px $ac-section-bg, 0 0 4px $ac-section-bg, 0 0 4px $ac-section-bg, 0 0 4px $ac-section-bg, 0 0 4px $ac-section-bg;
          -webkit-font-smoothing: antialiased;

          .game-title {
            font-family: 'yg-jalnan';
            color: white;
            font-size: min(max(100%, 10vw), 300%);
          }
          .game-description {
            color: white;
            font-size: min(max(50%, 5vw), 150%);
            /*font-size: 5vw;*/
          }
        }
      }

      .section {
        background-color: white;

        .section-contents {
          display: flex;
          flex-wrap: wrap;
          align-content: center;
        }

        .section-description {
          text-shadow: 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white;
          -webkit-font-smoothing: antialiased;

          .game-title{
            font-family: 'yg-jalnan';
            color: $ac-section-bg;
            font-size: min(max(100%, 10vw), 300%);
          }
          .game-description{
            color: $ac-section-bg;
            font-size: min(max(50%, 5vw), 150%);
          }
        }
      }


      .game-button {
        background-color: #e73042 !important;
        background-image: linear-gradient(180deg, #e73042 50%, #f1162b 50%, #f1162b );

        background-repeat: repeat-x;

        display: inline-block;
        outline: none;
        cursor: pointer;
        text-align: center;
        text-decoration: none;
        font-family: 'yg-jalnan';
        color: white;
        font-size: min(max(100%, 5vw), 200%);

        padding: 0.1em 0.5em;
        text-shadow: 0 1px 1px rgba(0,0,0,.3);

        -webkit-border-radius: 5rem;
        -moz-border-radius: 5rem;
        border-radius: 5rem;
        border: 5px solid #fff;

        -webkit-box-shadow: 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%), 0 5px 5px -3px rgb(0 0 0 / 20%);
        box-shadow: 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%), 0 5px 5px -3px rgb(0 0 0 / 20%);
      }
    }

  }

</style>